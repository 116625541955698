import React from "react"
import { Helmet } from "react-helmet"
import config from "../../data/SiteConfig"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import options from "../html-react-parsing-options"
import ArticleContainer from "../components/ArticleContainer"
import ArticleBody from "../components/ArticleBody"
import ArticleImage from "../components/ArticleImage"
import { css } from "@emotion/react"
import Text from "../styles/typography"
import { colorPrimatives } from "../styles/theme"
import Basmala from "../components/Basmala"

function AboutPage({ data }) {
  const {
    frontmatter: { photo, title },
    html,
  } = data.markdownRemark

  return (
    <>
      <Helmet title={`About | ${config.siteTitle}`} />
      <ArticleContainer>
        <ArticleBody>
          <Text
            el="h1"
            css={css`
              padding-top: 2rem;
            `}
          >
            {title}
          </Text>
          <ArticleImage
            src={photo}
            css={css`
              border: 2px solid ${colorPrimatives.tumbleweed};
              max-width: 300px;
              border-radius: 100%;
              margin-bottom: 2rem;
              margin-top: 1rem;
            `}
          />
          <Basmala />
          {html && parse(html, options())}
        </ArticleBody>
      </ArticleContainer>
    </>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutQuery {
    markdownRemark(fields: { slug: { regex: "/pages/about/" } }) {
      frontmatter {
        photo
        title
      }
      html
    }
  }
`
